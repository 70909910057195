import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { IAnalysis, IFinding } from "@telespot/analysis-refactor/data-access";
import { AnalysisProtocolTask, StepTask } from "@telespot/sdk";
import { RecorderService } from "@telespot/shared/session-recorder";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { AnalysisInputComponent } from "../analysis-input/analysis-input.component";

@Component({
  selector: "ts-analysis-form",
  templateUrl: "./analysis-form.component.html",
  styleUrls: ["./analysis-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisFormComponent implements OnInit, OnDestroy, OnChanges {
  private _destroy$ = new Subject<void>();

  public form: UntypedFormGroup;

  @ViewChildren(AnalysisInputComponent)
  classInputs: QueryList<AnalysisInputComponent>;

  @Output() analysisUpdateEvent = new EventEmitter<any[]>();
  @Output() analysisCreateEvent = new EventEmitter<any[]>();

  @Input() isViewMode: boolean;
  @Input() direction: "horizontal" | "vertical" = "vertical";
  @Input() mobile: boolean;
  @Input() findings: IFinding[] = [];
  @Input() analysis: IAnalysis;
  @Input() pipelineId: string;
  @Input() tasks: AnalysisProtocolTask[];
  @Input() taskFilter: (task: AnalysisProtocolTask) => boolean = () => true;

  constructor(
    @Optional() private __recorderService: RecorderService,
    private fb: FormBuilder
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const isChangeOfActiveAnalysis =
      changes.analysis?.currentValue?.id !==
      changes.analysis?.previousValue?.id;
    const hasViewModeChanged =
      changes.isViewMode?.currentValue !== changes.isViewMode?.previousValue;

    if (isChangeOfActiveAnalysis) this.updateFormWithAnalysisValues();
    if (hasViewModeChanged)
      this.isViewMode ? this.form?.disable() : this.form?.enable();
  }

  ngOnInit() {
    this.buildForm();
    this.updateFormWithAnalysisValues();
  }

  ngOnDestroy() {
    this._destroy$.next();
  }

  private updateFormWithAnalysisValues() {
    if (!this.form) return;

    for (const control of Object.keys(this.form.controls)) {
      const task = this.tasks.find((candidate) => candidate.name === control);

      if (task.roiSelection) return;

      const raw = (this.findings || []).find(
        (f) => f.taskId === task.stepId
      )?.data;

      if (task.type === "text") {
        this.form.controls[control].setValue(raw);
        continue;
      }
      if (!raw) continue;

      if (task.type === StepTask.CLASSIFICATION && task?.multiple) {
        if (raw instanceof Array) {
          (raw || []).forEach((rawValue) => {
            const controls = (this.form.controls[control] as FormGroup)
              .controls;
            const optionCtrl = controls[rawValue];
            if (!optionCtrl) return;
            optionCtrl.setValue(true);
          });

          continue;
        }
      }

      this.form.controls[control].setValue(raw);
    }
  }

  private buildControlFrom(task: AnalysisProtocolTask): UntypedFormControl {
    let value;

    if (task.type === StepTask.CLASSIFICATION && task?.multiple) {
      value = new FormGroup(
        task.options.reduce((formGroupConfig, option) => {
          formGroupConfig[option.uuid] = this.fb.control(false);
          return formGroupConfig;
        }, {})
      );

      return value;
    } else {
      value = "";
    }

    return new UntypedFormControl({ value, disabled: this.isViewMode });
  }

  private buildForm() {
    if (!this.tasks) return;

    const formConfig = this.tasks.reduce(
      (config, task) =>
        task.roiSelection
          ? config
          : {
              ...config,
              [task.name]: this.buildControlFrom(task),
            },
      {}
    );

    this.form = new UntypedFormGroup(formConfig);

    this.form.valueChanges
      .pipe(debounceTime(300), takeUntil(this._destroy$))
      .subscribe((_) => {
        const activeElement = document.activeElement as HTMLElement;
        const activeElementId = activeElement?.id;

        this.updateAnalysisWithFormValues();

        setTimeout(() => {
          const elementToFocus = document.getElementById(activeElementId);
          elementToFocus?.focus();
        });
      });
  }

  public updateAnalysisWithFormValues() {
    if (!this.form) return;

    let updatedFindings = [];

    const controls = Object.entries(this.form.controls).filter(
      ([controlName, formControl]) =>
        formControl.dirty ||
        this.tasks.find((task) => task.name === controlName).roiSelection
    );

    for (const [controlName, formControl] of controls) {
      const task = this.tasks.find((t) => t.name === controlName);

      if (task.roiSelection) continue;

      let selectedOptions;
      let findingIndex;

      switch (true) {
        case formControl.value instanceof Object:
          findingIndex = updatedFindings.findIndex(
            (f) => f.taskId === task.stepId
          );
          selectedOptions = Object.keys(formControl.value).filter(
            (o) => formControl.value[o]
          );
          if (findingIndex === -1) {
            updatedFindings.push({
              id: this.findings.find((f) => f.taskId === task.stepId)?.id,
              analysisId: this.analysis?.id,
              value: [...selectedOptions],
              taskId: task.stepId,
            });
          } else {
            updatedFindings[findingIndex] = {
              ...updatedFindings[findingIndex],
              value: [
                ...(updatedFindings[findingIndex]?.value || []),
                ...selectedOptions,
              ],
            };
          }
          break;
        default:
          updatedFindings.push({
            id: this.findings.find((f) => f.taskId === task.stepId)?.id,
            analysisId: this.analysis?.id,
            value: formControl.value,
            taskId: task.stepId,
          });
          break;
      }
    }

    //Update findings and analysis if there have been changes
    updatedFindings = updatedFindings.filter((f) => {
      const originalFinding = this.findings.find(
        (oFinding) => oFinding.id === f?.id
      );
      if (!originalFinding) return true;
      if (originalFinding?.data !== f?.value) return true;
      return false;
    });

    if (updatedFindings.length === 0) return;
    if (!this.analysis) {
      return this.analysisCreateEvent.emit(updatedFindings);
    }

    this.analysisUpdateEvent.emit(updatedFindings);
  }
}

<div class="analysis-panel custom-scrollbar" *ngIf="{
    asset: selectedAsset$ | async,
    sample: selectedSample$ | async,
    currentAnalyst: currentAnalyst$ | async,
    groups: tasksGroups$ | async,
    analysisContext: context$ | async,
    loadingProtocol: loadingProtocol$ | async,
    loadingAnalysis: loadingAnalysis$ | async,
    saved: saved$ | async,
    isAnalystCurrentUser: isAnalystCurrentUser$ | async
  } as state" [class.saved]="state.saved">
  <div class="flex-row justify-content-around analysis-context-selection">
    <div class="analysis-context-selection__option" [class.active]="state.analysisContext === 'asset'"
      (click)="toggleSample(false, state.analysisContext)" data-cy="toggle-asset-tasks"
      tsAnalytics="switch_to_asset_analysis">
      <i class="ri-image-line"></i>
      {{ "core.assettype.image" | translate | uppercase }}
    </div>
    <div class="analysis-context-selection__option" [class.active]="state.analysisContext === 'sample'"
      (click)="toggleSample(true, state.analysisContext)" data-cy="toggle-sample-tasks"
      tsAnalytics="switch_to_sample_analysis">
      <i class="ri-grid-line"></i>
      {{ "core.sample" | translate | uppercase }}
    </div>
    <ng-content select="span" class="analysis-context-selection__option"></ng-content>
  </div>
  <mat-expansion-panel class="custom-mat-expansion-panel analysis-expansion-panel" #exp [expanded]="expanded">
    <ng-content select="[analysisPanelHeader]"></ng-content>
    <ng-container *ngIf="
        state.asset?.data &&
        state.analysisContext === 'asset' &&
        state.sample.methodType?.assetDataFields?.length >= 1 &&
        hasAcquisitionData(state.asset?.data)
      ">
      <mat-expansion-panel style="padding-top: 0px" [expanded]="true"
        class="custom-mat-expansion-panel acquisition-data-expansion-panel">
        <mat-expansion-panel-header expandedHeight="32px" collapsedHeight="32px"><mat-panel-title
            class="flex-row justify-content-between">
            <h4 [attr.data-cy]="'acquisition-data'" style="margin-bottom: 0px">
              {{ "title.acquisition_data" | translate }}
            </h4>
          </mat-panel-title></mat-expansion-panel-header>
        <ts-acquisition-data [acquisitionData]="state.asset.data"
          [assetFields]="state.sample.methodType?.assetDataFields || []"
          [isEditable]="acquisitionDataEditable | async"></ts-acquisition-data>
      </mat-expansion-panel>
      <hr style="margin: 0" />
    </ng-container>
    <ng-container *ngIf="!state.loadingProtocol">
      <ng-container *ngIf="state.groups.length > 0; else noAnalysisTypes">
        <mat-expansion-panel class="custom-mat-expansion-panel analysis-type-expansion-panel"
          *ngFor="let group of state.groups" [expanded]="true" style="padding-top: 0px">
          <mat-expansion-panel-header class="analysis-expansion-panel-header" expandedHeight="32px"
            collapsedHeight="32px">
            <mat-panel-title class="flex-row justify-content-between">
              <h4 [attr.data-cy]="'analysis_type_name_' + group.name" style="margin-bottom: 0px">
                {{ group.name }}
              </h4>
              <!-- <ng-container *ngIfRole>
                <i
                  matTooltip="Changes not yet saved"
                  *ngIf="analysisForm.form?.dirty"
                  class="ri-save-2-line text-gray"
                ></i>
              </ng-container> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ts-analysis-form (analysisCreateEvent)="
              createAnalysis(
                {
                  pipelineId: group.id,
                  createdBy: state.currentAnalyst,
                  sampleId: state.sample?.id,
                  assetId:
                    state.analysisContext === 'asset'
                      ? state.asset?.id
                      : undefined
                },
                state.isAnalystCurrentUser && !state.loadingAnalysis,
                $event
              )
            " (analysisUpdateEvent)="updateAnalysis($event)" [isViewMode]="!state.isAnalystCurrentUser"
            [findings]="getActiveFindings(group.id) | async" [analysis]="getActiveAnalysis(group.id) | async"
            [pipelineId]="group.id" [tasks]="group.tasks" #analysisForm>
          </ts-analysis-form>
          <ng-content select="[analysisPanelActions]"></ng-content>
        </mat-expansion-panel>
      </ng-container>
      <ng-template #noAnalysisTypes>
        <div class="text-secondary" style="text-align: center; margin: 20px">
          <i class="ri-alert-line"></i>
          <!-- REVIEW: message is displayed also while loading -->
          {{ "alert.no_protocol_in_this_context" | translate }}
        </div>
      </ng-template>
    </ng-container>
    <ng-template #loading>
      <div style="text-align: center; padding: 10px">
        <span class="flex-row" class="loading-indicator">
          <i class="ri-refresh-line spin"></i>
          {{ "label.loading" | translate }}...
        </span>
      </div>
    </ng-template>
  </mat-expansion-panel>
</div>
